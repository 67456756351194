// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/cards/upper_cutout.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fingerprint-background-1[data-v-0648e7f0]{background-color:#000;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:right -150px bottom;background-repeat:no-repeat;background-size:cover;position:relative}@media only screen and (min-width:600px){.fingerprint-background-1[data-v-0648e7f0]{background-position:right calc(20% - 80px) bottom -20px}}.card-text[data-v-0648e7f0]{text-shadow:0 2px 10px #000}.coming-soon-button[data-v-0648e7f0]{background:gray!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
