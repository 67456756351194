
import ValidationResponse from "@/components/base/api/ValidationResponse"
import BusinessInvitationModal from "@/components/school/network/BusinessInvitationModal"
import Button from "@/components/base/Button"
import Modal from "@/components/base/modal/Modal"
import Card from "@/components/base/card/Card"

import { mdiEmail } from "@mdi/js"

export default {
    name: "InviteBusinessCard",
    components: {
        BusinessInvitationModal,
        ValidationResponse,
        Button,
        Modal,
        Card,
    },
    props: {
        schoolSlug: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            showInviteModal: false,
            validationData: null,
            mdiEmail: mdiEmail,
        }
    },
    methods: {
        sendInvitation() {
            this.$axios
                .post(
                    "/api/schools/" + this.schoolSlug + "/invite_business_site",
                    {
                        email: this.email,
                    }
                )
                .then((response) => {
                    return response.data.data
                })
                .catch((error) => {
                    this.validationData = error.response.data
                })
        },
        onOpen() {
            this.showInviteModal = true
        },
    },
}
